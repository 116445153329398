
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCSGDuiuzpkHq6xoV-aogV0K38h9bGL8ck",
  authDomain: "vidya-laxmi.firebaseapp.com",
  projectId: "vidya-laxmi",
  storageBucket: "vidya-laxmi.appspot.com",
  messagingSenderId: "779854745699",
  appId: "1:779854745699:web:9eb95e841570d58f76a856"
};


const app = initializeApp(firebaseConfig);
export const imageDb= getStorage(app)

