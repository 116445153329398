import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { imageDb } from '../../Firebase';

const Homepage1 = () => {
    const [data, setData] = useState({
        title: '',
        content: '',
        urls: [],
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [video, setVideo] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        // Fetch data from the GET API for section-3
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/home/get`, {
            section: 'section-3'
        })
            .then(response => {
                if (response.data) {
                    const fetchedData = response.data;
                    setData({
                        title: fetchedData.title || '',
                        content: fetchedData.content || '',
                        urls: fetchedData.url || [],
                    });
                    if (fetchedData.url && fetchedData.url.length > 0) {
                        setVideoUrl(fetchedData.url[0]);
                    }
                } else {
                    setError(new Error('No data found'));
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleVideoChange = (e) => {
        if (e.target.files[0]) {
            setVideo(e.target.files[0]);
        }
    };

    const handleUpload = () => {
        if (video) {
            const storageRef = ref(imageDb, `videos/${video.name}`);
            uploadBytes(storageRef, video)
                .then(snapshot => getDownloadURL(snapshot.ref))
                .then(url => {
                    setVideoUrl(url);
                    const newUrls = [...data.urls];
                    newUrls[0] = url;
                    setData(prevData => ({
                        ...prevData,
                        urls: newUrls,
                    }));
                    alert('Video uploaded successfully');
                })
                .catch(error => {
                    console.error('Error uploading video: ', error);
                    alert('Error uploading video');
                });
        } else {
            alert('Please select a video to upload');
        }
    };

    const handleSave = () => {
        if (!data.title || !data.content || data.urls.length === 0) {
            alert('All fields are required');
            return;
        }

        const payload = {
            title: data.title,
            content: data.content,
            urls: data.urls.length > 0 ? data.urls : [videoUrl],
            section: "section-3"
        };

        console.log('Data being sent:', payload);

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/home/add`, payload)
            .then(response => {
                alert('Data saved successfully');
                setIsEditing(false);
            })
            .catch(error => {
                console.error('Error saving data:', error);
                alert('Error saving data');
            });
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    if (loading) return <div style={{textAlign:"center"}}>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="admin-panel">
            <h2>Homepage Section 3</h2>
            {!isEditing && (
                <div>
                    <p>Title: {data.title}</p>
                    <p>Content: {data.content}</p>
                    {videoUrl && <video src={videoUrl} controls style={{ width: '100%', height: 'auto' }} />}
                    <button  className='btn' onClick={handleEdit}>Edit</button>
                </div>
            )}
            {isEditing && (
                <div>
                    <label>
                        Title:
                        <input
                            type="text"
                            name="title"
                            value={data.title}
                            onChange={handleInputChange}
                        />
                    </label>
                    <br />
                    <label>
                        {/* Content:
                        <textarea
                            name="content"
                            value={data.content}
                            onChange={handleInputChange}
                        /> */}
                    </label>
                    <br />
                    <label>
                        Upload Video:
                        <input
                            type="file"
                            accept="video/*"
                            onChange={handleVideoChange}
                        />
                    </label>
                    <br />
                    {videoUrl && <video src={videoUrl} controls style={{ width: '100%', height: 'auto' }} />}
                    <br />
                    <button className='btn' onClick={handleUpload}>Upload</button>
                    <button className='btn'  onClick={handleSave}>Save</button>
                </div>
            )}
        </div>
    );
};

export default Homepage1;
