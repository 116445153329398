import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faHome, faPalette, faBuilding, faLayerGroup, faPlus, faChevronDown, faChevronRight,faDiagramProject, faAddressCard, faLandmark, faDumpsterFire, faIgloo } from '@fortawesome/free-solid-svg-icons';
import { Link, Routes, Route } from 'react-router-dom';
import Homepage from '../Adminpages/Homepage';
import Interier from '../Adminpages/Interier';
import Exterior from '../Adminpages/Exterior';
import Teame from '../About/Teame';
import Services from '../About/Services';
import Gallery from '../Adminpages/Gallery';
import Commercial from '../Adminpages/Commercial';
import Residential from '../Adminpages/Residential';
import Property from '../Adminpages/Property';

const Dashboard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [ProjectDropdown, setProjectDropdown] = useState(false);
    const [AboutDropdown, setAboutDropdown] = useState(false);
    const[ConstructionDropdown, setConstructionDropdown] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const toggleProjectDropdown = () => {
        setProjectDropdown(!ProjectDropdown);
    };
    const toggleAboutDropdown= () =>{
        setAboutDropdown(!AboutDropdown);
    }
    const toggleConstructionDropdown = () =>{
        setConstructionDropdown(!ConstructionDropdown);
    }

    return (
        <div className='row mx-0'>
            <div className={isOpen ? '' : 'col-lg-2'}>
                <div className={`sidebar ${isOpen ? 'open ' : ''}`}>
                    <div className="admin-title">Admin</div>
                    <button className="toggle-button" onClick={toggleSidebar}>
                        <FontAwesomeIcon icon={isOpen ? faBars : faTimes} />
                    </button>
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link to="Homepage" className="nav-link">
                                <FontAwesomeIcon icon={faHome} />
                                Homepage
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link onClick={toggleProjectDropdown} className="nav-link">
                           <FontAwesomeIcon icon={faDiagramProject} />
                                Projects <FontAwesomeIcon icon={ProjectDropdown ? faChevronDown : faChevronRight} style={{ fontSize: '0.8em', marginLeft: "4px" }} />
                            </Link>
                            {ProjectDropdown && (
                                <ul className="nav flex-column pl-3">
                                    <li className="nav-item">
                                        <Link to="Interier" className="nav-link">
                                            <FontAwesomeIcon icon={faPalette} />
                                            Interior Design
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="Exterior" className="nav-link">
                                            <FontAwesomeIcon icon={faBuilding} />
                                            Architecture
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="property" className="nav-link">
                                            <FontAwesomeIcon icon={faLandmark} />
                                           Property
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>


                     
                        <li className="nav-item">
                            <Link onClick={toggleAboutDropdown} className="nav-link">
                           <FontAwesomeIcon icon={faAddressCard} />
                                About <FontAwesomeIcon icon={AboutDropdown ? faChevronDown : faChevronRight} style={{ fontSize: '0.8em', marginLeft: "4px" }} />
                            </Link>
                            {AboutDropdown && (
                                <ul className="nav flex-column pl-3">

                        <li className="nav-item">
                            <Link to="Teame" className="nav-link">
                                <FontAwesomeIcon icon={faLayerGroup} />
                                Our Team
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="Services" className="nav-link">
                                <FontAwesomeIcon icon={faLayerGroup} />
                                Services
                            </Link>
                        </li>
                        </ul>
                            )}
                        </li>



                        <li className="nav-item">
                            <Link onClick={toggleConstructionDropdown} className="nav-link">
                           <FontAwesomeIcon icon={faAddressCard} />
                                Construction <FontAwesomeIcon icon={ConstructionDropdown ? faChevronDown : faChevronRight} style={{ fontSize: '0.8em', marginLeft: "4px" }} />
                            </Link>
                            {ConstructionDropdown && (
                                <ul className="nav flex-column pl-3">

                        <li className="nav-item">
                            <Link to="Commercial" className="nav-link">
                                <FontAwesomeIcon icon={faDumpsterFire} />
                                Commercial
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="Residential" className="nav-link">
                                <FontAwesomeIcon icon={faIgloo} />
                                Residential
                            </Link>
                        </li>
                        </ul>
                            )}
                        </li>

                     



                        <li className="nav-item">
                            <Link to="Gallery" className="nav-link">
                                <FontAwesomeIcon icon={faLayerGroup} />
                                Gallery
                            </Link>
                        </li>
                       
                    </ul>
                </div>
            </div>

            <div className={isOpen ? 'col-lg-12' : 'col-lg-10'}>
                <Routes>
                    <Route path="/Homepage" element={<Homepage />} />
                    <Route path="/Interier" element={<Interier />} />
                    <Route path='/Exterior' element={<Exterior />} />
                    <Route path= "Commercial" element={<Commercial/>}/>
                    <Route path= "Residential" element={<Residential/>}/>
                    <Route path="/Teame" element={<Teame />} />
                    <Route path="/Services" element={<Services />} />
                    <Route path="/Gallery" element={<Gallery />} />
                    <Route path="/property" element={<Property/>}/>
                </Routes>
            </div>
        </div>
    );
};

export default Dashboard;
