import React from 'react'
import { Route, Routes } from 'react-router-dom';
// import Homepage from '../Component/Adminpages/Homepage';
import Loginform from '../Component/loginpage/Loginform';
import Homepage from '../Component/Adminpages/Homepage';

import Dashbord from '../Component/Dashboard/Dashbord';
import Interier from '../Component/Adminpages/Interier';
import Exterior from '../Component/Adminpages/Exterior';
import Teame from '../Component/About/Teame';
import Services from '../Component/About/Services';
import Gallery from '../Component/Adminpages/Gallery';
import Commercial from '../Component/Adminpages/Commercial';
import Testimonial from '../Component/Adminpages/Testemonial';
// import Homepage1 from '../Component/homepage1/Homepage1';

const Main = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Loginform />} />
       <Route  path="Interier" element={<Interier/>}/>
        <Route path="/Homepage" element={<Homepage />} />
        <Route path="/Dashbord" element={<Dashbord />} />
        <Route path="/Dashbord/*" element={<Dashbord />} />
        <Route path ="/Exterior" element ={<Exterior/>}/>
        <Route path ="/Teame" element ={<Teame/>}/>
        <Route path= "/Services" element={<Services/>}/>
        <Route path= "/Gallery" element={<Gallery/>}/>
        <Route path= "/commercial" element={<Commercial/>}/>
        <Route path= "/Testimonial" element={<Testimonial/>}/>
        {/* <Route patgh= "/homepage1" element={<Homepage1/>}/> */}
      </Routes>
    </div>
  )
}

export default Main
