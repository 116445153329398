import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getDownloadURL, ref, uploadBytes, deleteObject } from "firebase/storage";
import { imageDb } from '../../Firebase';

const Gallery = () => {
  const [editMode, setEditMode] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const fetchImages = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/gallery/get`)
      .then((res) => {
        setImageUrls(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleAddImage = async () => {
    if (selectedFile) {
      const storageRef = ref(imageDb, `images/${selectedFile.name}`);
      try {
        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, selectedFile);
        const imageUrl = await getDownloadURL(storageRef);

        const payload = {
          url: [imageUrl]
        };

        console.log('Sending payload to API:', payload);

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/gallery/add`, payload);

        console.log('API response:', response.data);

        if (response.data && response.data.message === 'Image URL added successfully') {
          const newImageUrl = imageUrl;

          setUploadedImageUrls([...uploadedImageUrls, newImageUrl]);
          setImageUrls([...imageUrls, newImageUrl]);
        } else {
          console.error('Unexpected API response structure:', response.data);
        }

        setSelectedFile(null);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleDeleteImage = async (index) => {
    try {
      // Assuming the API expects the index to delete
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/gallery/delete/${index}`);
      
      if (response.status === 200) {
        // Update the state to remove the deleted image
        const newImageUrls = imageUrls.filter((_, i) => i !== index);
        setImageUrls(newImageUrls);
      } else {
        console.error('Failed to delete image');
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  return (
    <div className='int_container'>
      <div className="leaving">
        <h1 style={{ textAlign: "center" }}>Gallery</h1>
        <div className="img_living">
          {imageUrls.map((imageUrl, index) => (
            <div key={index} className="image-item">
              <img src={imageUrl} alt={`Image ${index}`} />
              {editMode && (
                <div className="edit-buttons">
                  <button onClick={() => handleDeleteImage(index)} style={{ textAlign: "center",marginRight: "" }}>Delete</button>
                  {/* <button >Update</button> */}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="btn1">
          <button onClick={toggleEditMode}>{editMode ? 'Save' : 'Edit'}</button>
        </div>
        {editMode && (
          <div className="edit-form">
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleAddImage}>Add Image</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
