import React, { useState, useEffect } from 'react';
import { imageDb } from '../../Firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [newTestimonial, setNewTestimonial] = useState({
    name: '',
    content: '',
    image: null,  
    rating: ''
  });

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/testimonial/get-all`)
      .then(response => response.json())
      .then(data => setTestimonials(data))
      .catch(error => console.error('Error fetching testimonials:', error));
  };

  const handleInputChange = e => {
    const { name, value, files } = e.target;
    setNewTestimonial(prevState => ({
      ...prevState,
      [name]: files ? files[0] : value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      let imageUrl = '';

      if (newTestimonial.image) {
        const storageRef = ref(imageDb, `images/${newTestimonial.image.name}`);
        await uploadBytes(storageRef, newTestimonial.image);
        imageUrl = await getDownloadURL(storageRef);
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/testimonial/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: newTestimonial.name,
          content: newTestimonial.content,
          image: imageUrl,
          rating: newTestimonial.rating
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add testimonial');
      }
      
      fetchTestimonials();
      setNewTestimonial({
        name: '',
        content: '',
        image: null,
        rating: ''
      });
      alert('Testimonial added successfully!');
    } catch (error) {
      console.error('Error adding testimonial:', error);
      alert('Error adding testimonial');
    }
  };

  const handleDelete = async id => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/testimonial/delete/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete testimonial');
      }

      fetchTestimonials();
      alert('Testimonial deleted successfully!');
    } catch (error) {
      console.error('Error deleting testimonial:', error);
      alert('Error deleting testimonial');
    }
  };

  return (
    <div className="admin-panel">
      <h2>Testimonials Admin Panel</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          value={newTestimonial.name}
          onChange={handleInputChange}
          placeholder="Name"
          required
        />
        <input
          type="text"
          name="content"
          value={newTestimonial.content}
          onChange={handleInputChange}
          placeholder="Content"
          required
        />
        <input
          type="file"
          name="image"
          onChange={handleInputChange}
          placeholder="Image"
          required
        />
        <input
          type="text"
          name="rating"
          value={newTestimonial.rating}
          onChange={handleInputChange}
          placeholder="Rating"
          required
        />
        <button type="submit">Add</button>
      </form>
      <div className="testimonials-list">
        {testimonials.map(testimonial => (
          <div key={testimonial._id} className="testimonial-item">
            <p>{testimonial.content}</p>
            {testimonial.image && <img src={testimonial.image} alt="Testimonial" style={{ width: "250px" }} />}
            <button onClick={() => handleDelete(testimonial._id)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
