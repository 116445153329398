import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getDownloadURL, ref, uploadBytes, deleteObject } from "firebase/storage";
import { imageDb } from '../../Firebase';

const Interior = () => {
    const [editMode, setEditMode] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState({});
    const [uploadedImageUrls, setUploadedImageUrls] = useState({
        Commercial: [],
        residential: [],
        landscape: [],
        hospitality: []
    });

    const fetchImages = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/project/get-all`)
            .then((res) => {
                const fetchedImages = res.data.projects.reduce((acc, project) => {
                    project.sub_projects.forEach(subProject => {
                        const subProjectKey = subProject.sub_project_name;
                        if (acc[subProjectKey]) {
                            acc[subProjectKey] = [...acc[subProjectKey], ...subProject.urls];
                        } else {
                            acc[subProjectKey] = [...subProject.urls];
                        }
                    });
                    return acc;
                }, { Commercial: [], residential: [], landscape: [], hospitality: [] });
                setUploadedImageUrls(fetchedImages);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchImages();
    }, []);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const handleFileChange = (e, subProject) => {
        setSelectedFiles({
            ...selectedFiles,
            [subProject]: e.target.files[0]
        });
    };

    const handleAddImage = async (subProject) => {
        const selectedFile = selectedFiles[subProject];
        if (selectedFile) {
            const storageRef = ref(imageDb, `images/${selectedFile.name}`);
            try {
                await uploadBytes(storageRef, selectedFile);
                const imageUrl = await getDownloadURL(storageRef);

                const payload = {
                    project_name: "Architecture",
                    sub_projects: [
                        {
                            sub_project_name: subProject,
                            urls: [imageUrl]
                        }
                    ]
                };

                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/project/create`, payload);

                if (response.data) {
                    setUploadedImageUrls(prevState => ({
                        ...prevState,
                        [subProject]: [...prevState[subProject], imageUrl]
                    }));
                } else {
                    console.error('Unexpected API response structure:', response.data);
                }

                setSelectedFiles(prevState => ({
                    ...prevState,
                    [subProject]: null
                }));
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };
    const handleDeleteImage = async (subProject, imageUrl, index) => {
        // Optimistically update the state first
        setUploadedImageUrls(prevState => ({
            ...prevState,
            [subProject]: prevState[subProject].filter((_, i) => i !== index)
        }));
    
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/project/delete`, {
                data: {
                    projectName: "Architecture",
                    subprojectName: subProject,
                    index: index.toString()
                }
            });
    
            if (response.data && response.data.message === 'Image URL deleted successfully') {
                const storageRef = ref(imageDb, `images/${imageUrl.split('/').pop().split('#')[0].split('?')[0]}`);
                await deleteObject(storageRef);
                alert('Image deleted successfully!');
            } else {
                throw new Error('Failed to delete image from backend');
            }
        } catch (error) {
            console.error('Error deleting image:', error);
            alert('Deleted');
    
            // Revert the optimistic update in case of an error
            fetchImages();
        }
    };
    

    return (
        <div className='int_container'>
            <div className="leaving">
                <h1 style={{ textAlign: "center" }}>Architecture</h1>
                
                {['Commercial', 'residential', 'landscape', 'hospitality'].map((subProject, index) => (
                    <div key={index} className="sub_project">
                        <h1>{subProject}</h1>
                        <div className="img_container">
                            {uploadedImageUrls[subProject].map((imageUrl, idx) => (
                                <div key={idx} className="image-item">
                                    <img src={imageUrl} alt={`Image ${idx}`} />
                                    {editMode && (
                                        <div className="edit-buttons">
                                            <button onClick={() => handleDeleteImage(subProject, imageUrl, idx)}>Delete</button>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        {editMode && (
                            <div className="edit-form" style={{ marginLeft: "30%" }}>
                                <input 
                                    type="file" 
                                    onChange={(e) => handleFileChange(e, subProject)} 
                                />
                                <button onClick={() => handleAddImage(subProject)}>Add Image</button>
                            </div>
                        )}
                    </div>
                ))}
                
                <div className="btn1">
                    <button onClick={toggleEditMode}>{editMode ? 'Save' : 'Edit'}</button>
                </div>
            </div>
        </div>
    );
};

export default Interior;
