// import React, { useState } from 'react';
// import { useNavigate } from 'react-router';
// function Loginform() {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');

//   const navigate = useNavigate();

//   const handleUsernameChange = (event) => {
//     setUsername(event.target.value);
//   };

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     //  login logic here
//   };


//   return (<div>
//     <nav className="navbar">
//     <div className="logo">Logo</div> {/* Replace "Logo" with your logo image or text */}
//   </nav>
//     <div className='main_container'>
//       <div className="heading">Welcome to Vidya-Laxmi Associates Admin Panel</div>
//       <div className="centered">
//       <form onSubmit={handleSubmit}>
//         <h2>Login as Admin</h2>
//         <label>
//           Username:
//           <input type="text" placeholder="Enter username" value={username} onChange={handleUsernameChange} />
//         </label>
//         <label>
//           Password:
//           <input type="password" placeholder="Enter password" value={password} onChange={handlePasswordChange} />
//         </label>
//         <button type="submit" onClick={()=>{navigate('/Dashbord')}}>Login</button>
//       </form>
//     </div>
//     </div>
//     </div>
//   );
// }

// export default Loginform;














// import React from 'react'
// import { useState } from 'react'
// import { Storage } from '../../Firebase'
// import { ref } from 'firebase/storage'
// import
// const ImageUpload = () => {
//      const {ImageUplod, setImageUplod}= useState(null)
//     const uploadimage=() =>{
//         if(ImageUplod== null)return;
//         const imageref = ref(Storage, `images/${ImageUplod.name +}`)

//     }
//   return (
//     <div className='Appu' style={{marginTop:"100px"}}>
//       <input type="file" onChange={(event)=> {setImageUplod(event.target.files[0]);

//       }}/>
//       <button onClick={uploadimage}>upload image</button>
//     </div>
//   )
// }

// export default ImageUpload











import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';

function Loginform() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/login`, {
        email_id: username,
        password: password,
      });

      if (response.data.message === "Login successfully!") {
        // Store the token in local storage
        localStorage.setItem('token', response.data.data.token);
        navigate('/Dashbord');
      } else {
        setError('Invalid credentials');
      }
    } catch (error) {
      setError('Login failed. Please try again.');
      console.error('There was an error!', error);
    }
  };

  return (
    <div>
      <nav className="navbar">
        <div className="logo">Logo</div> {/* Replace "Logo" with your logo image or text */}
      </nav>
      <div className='main_container'>
        <div className="heading">Welcome to Vidya-Laxmi Associates Admin Panel</div>
        <div className="centered">
          <form onSubmit={handleSubmit}>
            <h2>Login as Admin</h2>
            <label>
              Username:
              <input type="text" placeholder="Enter username" value={username} onChange={handleUsernameChange} />
            </label>
            <label>
              Password:
              <input type="password" placeholder="Enter password" value={password} onChange={handlePasswordChange} />
            </label>
            <button type="submit">Login</button>
          </form>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      </div>
    </div>
  );
}

export default Loginform;
