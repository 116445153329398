

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { imageDb } from '../../Firebase';
import { getDownloadURL, listAll, ref, uploadBytes, deleteObject } from "firebase/storage";
import { v4 } from "uuid";

const Services = () => {
  const [editMode, setEditMode] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [newMember, setNewMember] = useState({ section:'',title: '', content: '', image: ''  });

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const fetchImages = () => {
    axios.get('https://vidya-laxmi-backend.azurewebsites.net/about/get')
      .then((res) => {
        setImageUrls(res.data.all_about_sections)
      })
      .catch((err) => {
        console.log(err)
      })
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleImageChange = (e, index) => {
    const newImageUrls = [...imageUrls];
    newImageUrls[index] = e.target.value;
    setImageUrls(newImageUrls);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMember(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddMember = async (e) => {
    e.preventDefault();
    try {
      const imageFile = newMember.image;
      const imgRef = ref(imageDb, `team/${v4()}`);
      await uploadBytes(imgRef, imageFile);
      const url = await getDownloadURL(imgRef);

      const response = await axios.post('https://vidya-laxmi-backend.azurewebsites.net/about/add',
        { ...newMember, image: url },
        { headers: { 'Content-Type': 'application/json' } });

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      setImageUrls([...imageUrls, url]);
      setNewMember({ name: '', designation: '', image: '' });
      console.log('New member added successfully!');
    } catch (error) {
      console.error('There was a problem adding the new member:', error.message);
    }
  };

  const handleDeleteImage = async (index) => {
    const imgRef = ref(imageDb, `/${imageUrls[index].split('/').pop().split('?')[0]}`);
    await deleteObject(imgRef);

    setImageUrls(imageUrls.filter((_, i) => i !== index));
  };

  return (
    <div className='int_container'>
      <div className="leaving">
        <h1 style={{ textAlign: "center" }}>Services</h1>
        <div className="img_living">
          {imageUrls.map((imageUrl, index) => {
            return (
              <div key={index}>
                {editMode ? (
                  <img src={imageUrl.image} alt={`Image ${index}`} />
                  // onChange={(e) => handleImageChange(e, index)}

                ) : (
                  <img src={imageUrl.image} alt={`Image ${index}`} />
                )}
                {editMode && (
                  <button onClick={() => handleDeleteImage(index)}>
                    <FaTrashAlt /> Delete
                  </button>
                )}
              </div>
            )
          })}
        </div>
        <div className="btn1">
          <button onClick={toggleEditMode}>{editMode ? 'Save' : 'Edit'}</button>
        </div>
        {editMode && (
          <div className="edit-mode1">
            <h2 style={{ textAlign: "center" }}>Add New sections</h2>
            <form onSubmit={handleAddMember}>
              <div>
                <label>Title:</label>
                <input
                  type="text"
                  name="name"
                  value={newMember.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Content:</label>
                <input
                  type="text"
                  name="designation"
                  value={newMember.designation}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label>Image:</label>
                <input
                  type="file"
                  name="image"
                  onChange={(e) => setNewMember({ ...newMember, image: e.target.files[0] })}
                  required
                />
              </div>
              <button type="submit">Add Member</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default Services























































// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { imageDb } from '../../Firebase'; 

// const Services = () => {
//   const [newTopic, setNewTopic] = useState({ section:'',title: '', content: '', image: '' });
//   const [imageFile, setImageFile] = useState(null);
//   const [topics, setTopics] = useState([]);

//   const fetchTopics = async () => {
//     axios.get('https://vidya-laxmi-backend.azurewebsites.net/about/get')
//       .then((res) => {
//         setImageUrls(res.data.all_about_sections)
//       })
//       .catch((err) => {
//         console.log(err)
//       })
//   };

//   useEffect(() => {
//     fetchImages();
//   }, []);


//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setNewTopic((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleImageChange = (e) => {
//     setImageFile(e.target.files[0]);
//   };

//   const handleAddTopic = async (e) => {
//     e.preventDefault();

//     if (imageFile) {
//       const imageRef = ref(imageDb, `images/${imageFile.name}`);
//       try {
//         await uploadBytes(imageRef, imageFile);
//         const imageUrl = await getDownloadURL(imageRef);

//         const topicWithImage = { ...newTopic, image: imageUrl };
//         const response = await axios.post('https://vidya-laxmi-backend.azurewebsites.net/about/add', topicWithImage, {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         });

//         if (response.status === 200) {
//           setNewTopic({ title: '', content: '', image: '' });
//           setImageFile(null);
//           alert('Topic added successfully!');
//           fetchTopics();  // Refresh the list after adding a new topic
//         } else {
//           throw new Error('Failed to add topic');
//         }
//       } catch (error) {
//         console.error('Error uploading image or adding topic:', error);
//         alert('Failed to add topic');
//       }
//     } else {
//       alert('Please select an image to upload');
//     }
//   };

//   return (
//     <div className="admin-panel">
//       <h2>Add New Topic</h2>
//       <form onSubmit={handleAddTopic}>
//         <div className="form-group">
//           <label>Title:</label>
//           <input
//             type="text"
//             name="title"
//             value={newTopic.title}
//             onChange={handleInputChange}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Content:</label>
//           <textarea
//             name="content"
//             value={newTopic.content}
//             onChange={handleInputChange}
//             required
//           />
//         </div>
//         <div className="form-group">
//           <label>Image:</label>
//           <input type="file" onChange={handleImageChange} accept="image/*" required />
//         </div>
//         <button type="submit">Add Topic</button>
//       </form>

//       <h2>Existing Topics</h2>
//       <div className="topics-list">
//         {topics.length > 0 ? (
//           topics.map((item, index) => (
//             <div key={index} className="topic-item">
//               <h3>{item.title}</h3>
//               <p>{item.content}</p>
//               <img src={item.image} alt={item.title} />
//             </div>
//           ))
//         ) : (
//           <p>No topics available.</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Services;
