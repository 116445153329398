
import './App.css';
import Homepage from './Component/Adminpages/Homepage';
// import Sidebar from './Component/Sidebar/Sidebar';

import LoginForm from './Component/loginpage/Loginform';
import { BrowserRouter } from 'react-router-dom'
import Main from './Routes/Main';
import ImageUpload from './Component/Firebase/ImageUpload';

function App() {
  return (
    <>
    <BrowserRouter>
      <div className="App">
        <Main/>
       
      </div>
    </BrowserRouter>
    
{/* <ImageUpload/> */}
    </>
  );
}

export default App;
