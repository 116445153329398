
import React, { useState } from 'react';
import Testimonial from '../Adminpages/Testemonial';
import Homepage1 from '../homepage1/Homepage1';
import Homepage2 from '../homepage1/Homepage2';
import Homepage3 from '../homepage1/Homepage3';
import Homepage4 from '../homepage1/Homepage4';



const Homepage = () => {
  const [editMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };


  return (
    <>
   
    <div className='mainhomepage'>
     
<Homepage1/>
<Homepage2/>
<Homepage3/>
<Homepage4/>
<Testimonial/>

    </div>
    
    </>
  )
}

export default Homepage
