import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { imageDb } from '../../Firebase';

const Homepage2 = () => {
    const [data, setData] = useState({
        title: '',
        content: '',
        urls: ['', ''], // Initialize with two empty URLs for two videos
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [videos, setVideos] = useState([null, null]);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        // Fetch data for section-2 from the POST API
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/home/get`, {
            section: 'section-2'
        })
            .then(response => {
                if (response.data) {
                    const fetchedData = response.data;
                    setData({
                        title: fetchedData.title || '',
                        content: fetchedData.content || '',
                        urls: fetchedData.url || ['', ''], // Ensure there are two URLs
                    });
                } else {
                    setError(new Error('No data found for section-2'));
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleVideoChange = (index) => (e) => {
        if (e.target.files[0]) {
            const newVideos = [...videos];
            newVideos[index] = e.target.files[0];
            setVideos(newVideos);
        }
    };

    const handleUpload = (index) => {
        const video = videos[index];
        if (video) {
            const storageRef = ref(imageDb, `videos/${video.name}`);
            uploadBytes(storageRef, video)
                .then(snapshot => getDownloadURL(snapshot.ref))
                .then(url => {
                    const newUrls = [...data.urls];
                    newUrls[index] = url;
                    setData(prevData => ({
                        ...prevData,
                        urls: newUrls,
                    }));
                    alert(`Video ${index + 1} uploaded successfully`);
                })
                .catch(error => {
                    console.error(`Error uploading video ${index + 1}: `, error);
                    alert(`Error uploading video ${index + 1}`);
                });
        } else {
            alert(`Please select a video to upload for video ${index + 1}`);
        }
    };

    const handleSave = () => {
        if (!data.title || !data.content || data.urls.some(url => !url)) {
            alert('All fields are required');
            return;
        }

        const payload = {
            title: data.title,
            content: data.content,
            urls: data.urls,
            section: "section-2"
        };

        console.log('Data being sent:', payload);

        axios.post(`${process.env.REACT_APP_BACKEND_URL}/home/add`, payload)
            .then(response => {
                alert('Data saved successfully');
                setIsEditing(false);
            })
            .catch(error => {
                console.error('Error saving data:', error.response ? error.response.data : error.message);
                alert(`Error saving data: ${error.response ? error.response.data : error.message}`);
            });
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    if (loading) return <div style={{textAlign:"center"}}>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="admin-panel">
            <h2>Homepage Section 2</h2>
            {!isEditing && (
                <div>
                    <p>Title: {data.title}</p>
                    <p>Content: {data.content}</p>
                    {data.urls[0] && <video src={data.urls[0]} controls style={{ width: '100%', height: 'auto' }} />}
                    {data.urls[1] && <video src={data.urls[1]} controls style={{ width: '100%', height: 'auto' }} />}
                    <button className='btn' onClick={handleEdit}>Edit</button>
                </div>
            )}
            {isEditing && (
                <div>
                    <label>
                        Title:
                        <input
                            type="text"
                            name="title"
                            value={data.title}
                            onChange={handleInputChange}
                        />
                    </label>
                    <br />
                    <label>
                        {/* Content: */}
                        {/* <textarea
                            name="content"
                            value={data.content}
                             onChange={handleInputChange}
                        /> */}
                    </label>
                    <br />
                    <label>
                        Upload Video 1:
                        <input
                            type="file"
                            accept="video/*"
                            onChange={handleVideoChange(0)}
                        />
                    </label>
                    <br />
                    {data.urls[0] && <video src={data.urls[0]} controls style={{ width: '100%', height: 'auto' }} />}
                    <button className='btn' onClick={() => handleUpload(0)}>Upload Video 1</button>
                    <br />
                    <label>
                        Upload Video 2:
                        <input
                            type="file"
                            accept="video/*"
                            onChange={handleVideoChange(1)}
                        />
                    </label>
                    <br />
                    {data.urls[1] && <video src={data.urls[1]} controls style={{ width: '100%', height: 'auto' }} />}
                    <button className='btn' onClick={() => handleUpload(1)}>Upload Video 2</button>
                    <br />
                    <button className='btn' onClick={handleSave}>Save</button>
                </div>
            )}
        </div>
    );
};

export default Homepage2;
