import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { imageDb } from '../../Firebase';

const Residential = () => {
  const [editMode, setEditMode] = useState(false);
  const [residentialUrls, setResidentialUrls] = useState([]);
  const [selectedResidentialFile, setSelectedResidentialFile] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const fetchImages = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/construction/get-residential`)
      .then((res) => {
        setImageUrls(res.data.residentialUrls || []); 
      })
      .catch((err) => {
        console.error('Error fetching images:', err);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleResidentialFileChange = (e) => {
    setSelectedResidentialFile(e.target.files[0]);
  };

  const handleAddImage = async () => {
    if (selectedResidentialFile) { 
      const storageRef = ref(imageDb, `images/${selectedResidentialFile.name}`); 

      try {
        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, selectedResidentialFile);
        const imageUrl = await getDownloadURL(storageRef);

        const payload = {
          residentialUrls: [imageUrl]
        };

        // Optimistically update the state
        const newImageUrl = imageUrl;
        setResidentialUrls([...residentialUrls, newImageUrl]);
        setImageUrls([...imageUrls, newImageUrl]);

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/construction/add`, payload);

        if (!(response.data && response.data.message === 'Image URL added successfully')) {
          console.error('Unexpected API response structure:', response.data);
          throw new Error('Failed to add image to backend');
        }

        setSelectedResidentialFile(null); 
      } catch (error) {
        console.error('Error uploading image:', error);
       

        // Revert the optimistic update in case of an error
        fetchImages();
      }
    }
  };

  const handleDeleteImage = async (index) => {
    // Optimistically update the state
    const imageUrl = imageUrls[index];
    setImageUrls(prevState => prevState.filter((_, i) => i !== index));
    setResidentialUrls(prevState => prevState.filter((_, i) => i !== index));

    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/construction/delete-residential`, {
        data: { index: index },
      });

      if (response.data && response.data.message === 'Image URL deleted successfully') {
        const storageRef = ref(imageDb, `images/${imageUrl.split('/').pop().split('#')[0].split('?')[0]}`);

        await deleteObject(storageRef);

        alert('Image deleted successfully!');
      } else {
        throw new Error('Failed to delete image from backend');
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      alert('Deleted.');

      // Revert the optimistic update in case of an error
      fetchImages();
    }
  };

  return (
    <div className='comrcil'>
      <h1 style={{textAlign:"center"}}>Residential Image Gallery</h1>
      <div className="gallery5">
        {imageUrls.map((url, index) => (
          <div key={index}>
            <img src={url} alt={`Residential ${index + 1}`} />
            {editMode && (
              <button onClick={() => handleDeleteImage(index)}>Delete</button>
            )}
          </div>
        ))}
      </div>
      <div className="btn1">
        <button onClick={toggleEditMode}>{editMode ? 'Save' : 'Edit'}</button>
      </div>
      {editMode && (
        <div className="edit-form">
          <input type="file" onChange={handleResidentialFileChange} />
          <button onClick={handleAddImage}>Add Residential Image</button>
        </div>
      )}
    </div>
  );
};

export default Residential;
