import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { imageDb } from '../../Firebase'; 

const Commercial = () => {
  const [editMode, setEditMode] = useState(false);
  const [commercialUrls, setCommercialUrls] = useState([]);
  const [selectedCommercialFile, setSelectedCommercialFile] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const fetchImages = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/construction/get-commercial`)
      .then((res) => {
        setImageUrls(res.data.commercialUrls || []); 
      })
      .catch((err) => {
        console.error('Error fetching images:', err);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleCommercialFileChange = (e) => {
    setSelectedCommercialFile(e.target.files[0]);
  };

  const handleAddImage = async () => {
    if (selectedCommercialFile) { 
      const storageRef = ref(imageDb, `images/${selectedCommercialFile.name}`); 

      try {
        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, selectedCommercialFile);
        const imageUrl = await getDownloadURL(storageRef);

        const payload = {
          commercialUrls: [imageUrl]
        };

        console.log('Sending payload to API:', payload);

        // Optimistically update the state
        const newImageUrl = imageUrl;
        setCommercialUrls([...commercialUrls, newImageUrl]);
        setImageUrls([...imageUrls, newImageUrl]);

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/construction/add`, payload);

        console.log('API response:', response.data);

        if (!(response.data && response.data.message === 'Image URL added successfully')) {
          console.error('Unexpected API response structure:', response.data);
          throw new Error('Failed to add image to backend');
        }

        setSelectedCommercialFile(null); 
      } catch (error) {
        console.error('Error uploading image:', error);
        

        fetchImages();
      }
    }
  };

  const handleDeleteImage = async (index) => {

    const imageUrl = imageUrls[index];
    setImageUrls(prevState => prevState.filter((_, i) => i !== index));
    setCommercialUrls(prevState => prevState.filter((_, i) => i !== index));

    try {
      
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/construction/delete-commercial`, {
        data: { index: index },
      });

      if (response.data && response.data.message === 'Image URL deleted successfully') {
        // Create a reference to the file to delete
        const storageRef = ref(imageDb, `images/${imageUrl.split('/').pop().split('#')[0].split('?')[0]}`);
  
        // Delete the file from Firebase Storage
        await deleteObject(storageRef);
  
        alert('Image deleted successfully!');
      } else {
        throw new Error('Failed to delete image from backend');
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      alert('Deleted.');

      // Revert the optimistic update in case of an error
      fetchImages();
    }
  };

  return (
    <div className='comrcil'>
      <h1 style={{textAlign:"center"}}>Commercial</h1>
      <div className="gallery5">
        {imageUrls.map((url, index) => (
          <div key={index}>
            <img src={url} alt={`Commercial ${index + 1}`} />
            {editMode && (
              <button onClick={() => handleDeleteImage(index)}>Delete</button>
            )}
          </div>
        ))}
      </div>
      <div className="btn1">
        <button onClick={toggleEditMode}>{editMode ? 'Save' : 'Edit'}</button>
      </div>
      {editMode && (
        <div className="edit-form">
          <input type="file" onChange={handleCommercialFileChange} />
          <button onClick={handleAddImage}>Add Commercial Image</button>
        </div> 
      )}
    </div>
  );
};

export default Commercial;
